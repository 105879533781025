import styled from '@emotion/styled';
import { Button as DefaultButton } from '@square-enix-private/vaquita-ui';
import { layout } from 'styled-system';

import Youtube from '~/assets/YT-play-button.png';
import { baseCSS } from './styles';

const PlayButton = styled(DefaultButton)`
  ${baseCSS};
  ${layout};
  background-image: url(${Youtube});
  background-size: contain;

  :hover {
    @supports (-ms-scroll-limit: 0) {
      background-image: url(${Youtube});
    }
  }

  ::after {
    @supports (clip-path: circle(100% at 50% 50%)) {
      background-image: url(${Youtube});
      background-size: contain;
      filter: invert(1);
    }
  }
`;

export default PlayButton;
