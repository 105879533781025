import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Box, Flex, Heading } from '@square-enix-private/vaquita-ui';
import {
  position,
  top,
  bottom,
  style,
  space,
  gridTemplateColumns
} from 'styled-system';
import { breakpoints } from '~/themes/base';
import Img from 'gatsby-image';
import PlayButton from '~/components/Buttons/PlayButton';
import GrungyTexture from '~/assets/grunge-thumbnails.png';
import ButtonBackHover from '~/assets/Buttons/Small/Button_hover.png';
import { InternalLink } from '~/components/Buttons';

const hoverTextColor = style({
  prop: 'hoverTextColor',
  cssProperty: 'color',
  key: 'colors'
});

export const CloseButtonWrapper = styled(Box)`
  max-width: 44px;
  ${space};
`;

export const CloseViewer = styled(InternalLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  filter: invert(1);
  ${top};
  background-size: cover;

  @media only screen and (orientation: landscape) and (max-width: ${breakpoints[1]}) {
    margin: 8px;
  }

  ::after {
    @supports (clip-path: circle(0% at 50% 50%)) {
      background-image: url(${ButtonBackHover});
      background-size: cover;
      filter: invert(1);
    }
  }

  @supports (-ms-scroll-limit: 0) {
    &:hover {
      background-image: url(${ButtonBackHover});
      background-size: cover;
      filter: unset;
      ${hoverTextColor};
    }
  }
`;

export const MainVideo = styled(Flex)`
  position: relative;
  flex-direction: column;
`;

export const HighlightsGrid = styled(Flex)`
  @media screen and (min-width: 580px) {
    display: grid;
    ${gridTemplateColumns};
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 32px;
    grid-auto-rows: auto;
  }
`;

export const ItemWrapper = styled(Flex)`
  width: 100%;
  position: relative;
  flex-direction: column;

  :first-of-type {
    margin-left: 0;
  }
  :last-of-type {
    margin-right: 0;
  }
`;

export const VideoContainer = styled(Box)`
  width: 100%;
  height: 0;
  padding-top: 56.25%;
`;

export const VideoItem = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Wrapper = styled(Flex)`
  width: 100%;
  position: relative;
  flex-direction: column;
`;

const ItemContainer = styled(Flex)`
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  div {
    transition: 0.5s;
    transition-timing-function: ease-in-out;
  }

  :hover {
    div {
      transform: scale(1.05);
      backface-visibility: hidden;
    }
    button {
      @supports (-ms-scroll-limit: 0) {
        filter: invert(1);
      }

      ::after {
        @supports (clip-path: circle(100% at 50% 50%)) {
          clip-path: circle(100% at 50% 50%);
        }
      }
    }
  }
`;

export const PlayContainer = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  background-image: url(${GrungyTexture});
  background-size: cover;
  background-position: center;
`;

export const Description = styled(Heading)`
  font-size: 22px;
  ${position};
  ${bottom};
  text-align: center;
`;

export const Item = ({ imgData, description, onClick, playVisible, alt }) => (
  <Wrapper onClick={onClick}>
    <ItemContainer width={1}>
      <Flex width={1}>
        <Box width={1}>
          <Img
            fluid={{ ...imgData, aspectRatio: 430 / 240 }}
            alt={alt}
            title={description}
          />
        </Box>
      </Flex>
      {playVisible && (
        <PlayContainer width={1} posY={Math.floor(Math.random() * 3)}>
          <PlayButton width={60} height={40} />
        </PlayContainer>
      )}
    </ItemContainer>
    <Description width={1} my="small">
      {description}
    </Description>
  </Wrapper>
);

Item.propTypes = {
  imgData: PropTypes.shape({
    fluid: PropTypes.object
  }),
  description: PropTypes.string,
  onClick: PropTypes.func,
  playVisible: PropTypes.bool,
  alt: PropTypes.string
};

Item.defaultProps = {
  imgData: { fluid: null },
  description: null,
  onClick: null,
  playVisible: false,
  alt: null
};
